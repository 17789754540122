<template>
	<div class="row">
		<div class="col-lg-6 col-12 lb pb-1 mt-1">
			<div class="row">
				<div class="col-12">
					<b>{{ $t('employeeIndividual.individualRemuneration') }}</b>
				</div>
				<div class="col-12">
					<InputNumber :disabled="disabled" v-model.number="individualCondition.individualRemuneration"> </InputNumber>
				</div>
			</div>
		</div>

		<div class="col-lg-6 col-12 lb pb-1 mt-1">
			<div class="row">
				<div class="col-12">
					<label
						><b>Bemærkning</b></label
					>
				</div>
				<div class="col-12">
					<InputComment v-model="individualCondition.personalMessage" :disabled="disabled" />
				</div>
			</div>
		</div>

		<div class="col-12">
			<hr />
			<router-link :to="{ name: 'CustodianAdd', params: { companyId: companyId, employeeId: employeeId } }">
				<b-button class="mb-3" variant="success">Opret ny depotværdi</b-button>
			</router-link>
			<BaseTable :items="individualCondition.custodianValueList" :fields="custodianFields">
				<template v-slot:cell(index)="data">
					{{ data.index + 1 }}
				</template>
				<template v-slot:cell(date)="data">
					{{ getDateAndMonth(data.item.date) }}
				</template>

				<template v-slot:cell(remove)="data">
					<router-link :to="{ name: 'CustodianEdit', params: { companyId: companyId, employeeId: employeeId, index: data.index } }">
						<b-button class="mb-3" variant="success">Ændr</b-button>
					</router-link>
					<BaseActionButton v-on:on-click="removeCustodian(data.index)" type="button" id="signupButton" class="btn-delete"> Fjern </BaseActionButton>
				</template>
			</BaseTable>
		</div>

		<div class="col-12">
			<hr />
			<router-link :to="{ name: 'TransferAdd', params: { companyId: companyId, employeeId: employeeId } }">
				<b-button class="mb-3" variant="success">Opret ny overførsel</b-button>
			</router-link>
			<BaseTable :items="individualCondition.transferList" :fields="fields">
				<template v-slot:cell(index)="data">
					{{ data.index + 1 }}
				</template>
				<template v-slot:cell(date)="data">
					{{ getDateAndMonth(data.item.date) }}
				</template>

				<template v-slot:cell(transferRecieved)="data">
					<div v-if="data.item.transferRecieved">
						<font-awesome-icon :icon="['fa', 'check']" class="icon fa-lg" style="color: green" />
					</div>
					<div v-if="!data.item.transferRecieved">
						<font-awesome-icon :icon="['fa', 'times']" class="icon fa-lg" style="color: red" />
					</div>
					<div v-else>-</div>
				</template>

				<template v-slot:cell(supplierId)="data">
					{{ getSupplier(data.item.supplierId) }}
				</template>

				<template v-slot:cell(remove)="data">
					<router-link :to="{ name: 'TransferEdit', params: { companyId: companyId, employeeId: employeeId, index: data.index } }">
						<b-button class="mb-3" variant="success">Ændr</b-button>
					</router-link>
					<BaseActionButton v-on:on-click="removeTransfer(data.index)" type="button" id="signupButton" class="btn-delete"> Fjern </BaseActionButton>
				</template>
			</BaseTable>
		</div>
	</div>
</template>

<script>
import InputDate from '@/modules/global/atomComponents/InputDate'
import InputNumber from '@/modules/global/atomComponents/InputNumber'
import InputComment from '@/modules/global/atomComponents/InputComment'
import BaseTable from '@/modules/global/components/BaseTable'
import DateMixin from '@/mixins/dateMixin'

export default {
	name: 'IndividualCondition',
	components: {
		InputDate,
		InputNumber,
		InputComment,
		BaseTable,
	},
	mixins: [DateMixin],
	props: {
		value: Object,
		disabled: Boolean,
	},
	data() {
		return {
			fields: [
				{ key: 'index', label: 'Index' },
				{ key: 'date', label: 'Rådgivningsdato' },
				{ key: 'transferRecieved', label: 'Overførsel modtaget' },
				{ key: 'value', label: 'Værdi' },
				{ key: 'supplierId', label: 'Selskab' },
				{ key: 'comment', label: 'Kommentar' },
				{ key: 'remove', label: 'Fjern' },
			],
			custodianFields: [
				{ key: 'index', label: 'Index' },
				{ key: 'date', label: 'Dato' },
				{ key: 'value', label: 'Værdi' },
				{ key: 'comment', label: 'Kommentar' },
				{ key: 'remove', label: 'Fjern' },
			],
		}
	},
	methods: {
		async removeTransfer(index) {
			this.clicked = true
			// Remove kyc index from the company
			this.employeePolicy.individualCondition.transferList.splice(index, 1)
			try {
				var companyId = this.companyId
				var employeeId = this.employeeId
				var id = this.id
				var result = await this.$store.dispatch('employeeVuex/updateEmployeePolicy', { id, companyId, employeeId, data: this.employeePolicy })
				this.toast('Success', 'En overførsel nu fjernet', true)
			} catch (err) {
				this.toast('Error', 'Noget gik galt' + err, false)
				this.clicked = false
			}
			this.clicked = false
		},
		getSupplier(supplierId) {
			const supplier = this.suppliers.find((supplier) => supplier.id === supplierId)
			return supplier ? supplier.name : ''
		},
	},
	computed: {
		individualCondition: {
			get() {
				return this.value
			},
			set(value) {
				this.$emit('input', value)
			},
		},
		employeePolicy() {
			return this.$store.getters['employeeVuex/employeePolicy']
		},
		companyId() {
			return this.$route.params.companyId
		},
		employeeId() {
			return this.$route.params.employeeId
		},
		suppliers() {
			return this.$store.getters['supplier/suppliers']
		},
		id() {
			return this.employeePolicy.id
		},
	},
	mounted() {},
}
</script>

<style></style>
